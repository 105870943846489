.periodica-list > a  > div {
    background-color: white;
    margin-bottom: -1px;
    padding: 8px;
    cursor: pointer;
    border: 1px solid lightgray;
    border-radius: 2px;
  }

.periodica-list > a  > div:hover {
    background-color: rgb(204,0,0);
    color: white;
}
