body {
  background-color: #f5f5f2;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  margin: 100px 40px 80px 40px;
}

.container {
  max-width: 728px;
  margin: 0 auto;
}

p {
  max-width: 728px;
}

h1 {
  margin-bottom: 24px;
  font-size: 1.2em;
}

a, .link {
  color: rgb(204,0,0);
  text-decoration: none;
  cursor: pointer;
}

header {
  z-index: 2;
}
