.grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 20px 0 40px;
}

.grid-item {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, .16), 0 0 1px 0 rgba(0, 0, 0, .08);
  transition: box-shadow .2s ease-in-out;
  width: 172px;
  background-color: white;
  margin-right: 16px;
  margin-bottom: 16px;
}

.grid-item > img {
  width: 100%;
  background-color: white;
}

.grid-item .grid-text {
  padding: 6px;
  margin: 0;
  line-height: 1.2em;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
}

.grid-item .sort-key {
    color: darkgrey;
    background-color: lightgrey;
    border-radius: 3px;
    padding: 1px 3px;
  }

.grid-item a {
  text-decoration: none;
}

.grid-item:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,.16),0 0 16px 0 rgba(0,0,0,.08);
}

.grid-item.placeholder {
  display: flex;
  flex-direction: column;
}

.grid-item.placeholder svg {
    margin: auto;
}

.grid-item.locked {
  position: relative;
}

.grid-item.locked img, .grid-item.locked p {
  opacity: 0.3;
}

.grid-item.locked svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
