header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 66px;
  padding: 12px 40px;
  background: linear-gradient(117deg, #fefefd, #fefefd 20%, #fafaf8 40%, #fafaf8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .16), 0 0 8px 0 rgba(0, 0, 0, .08);
  display: flex;
  box-sizing: border-box;
}

@media (max-width: 500px) {
  header {
    padding: 12px;
  }
}

header .right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

header .right > * {
  margin-left: 10px;
}

header .logo {
  background-image: url("logo.svg");
  height: 42px;
  width: 225px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 500px) {
    header .logo {
      background-image: url("logo-small.svg");
      height: 42px;
      width: 36.5px;
    }
}

