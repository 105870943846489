footer {
  min-height: 20px;
  padding: 12px 30px;
  background-color: #3d434b;
  color: lightgray;
  opacity: 0.85;
}

@media (min-width: 501px) {
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media (max-width: 500px) {
  footer {
    margin: 40px -40px -80px -40px;
    padding: 12px;
  }
}

footer a {
  color: lightgray !important;
}

footer div {
  display: inline-block;
}


@media (max-width: 500px) {
  footer .copyright {
    margin-top: 4px;
  }
}

@media (min-width: 501px) {
  footer .copyright {
    float: right;
  }
}

